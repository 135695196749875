import React from "react";
import { graphql } from "gatsby";

import Project from "../components/projects/project-link"
import SEO from "../components/seo";
import Layout from "../components/layout";

import HeroBanner from "../components/page-hero-banner";
import styles from "../scss/layouts/projects_index.module.scss";
import ProjectsCta from "../components/cta/cta_projects";


export default ({ data }) => {
  const pageData = data.markdownRemark;
  const categoryRegEx = /^\/projects\/([a-z0-9-]+)/;
  const projectCategory = categoryRegEx.exec(pageData.fields.slug)[1];
  const allProjects = data.allMarkdownRemark;

  //Go through projects to find only those with the same category as the page
  const projects = allProjects.nodes.filter(function(project) {
    if (categoryRegEx.exec(project.fields.slug)[1] === projectCategory) {
      return project;
    }
    return null;
  });

  // const heroImageSources = [
  //   data.largeHero.childImageSharp.fluid,
  //   {
  //     ...data.smallHero.childImageSharp.fluid,
  //     media: `(max-width: 800px)`,
  //   },
  // ]

  const heroImageSources = [
    pageData.frontmatter.largeHero.childImageSharp.fluid,
    {
      ...pageData.frontmatter.smallHero.childImageSharp.fluid,
      media: `(max-width: 800px)`,
    },
  ]

  let intro = '';

  if (pageData.frontmatter.intro) {
    intro = <div className={styles.projects_index}>
      <div className={styles.projects_index__inner}>
        <div dangerouslySetInnerHTML={{ __html: pageData.frontmatter.intro }} />
      </div>
    </div>
  }

  return (
    <Layout>
      <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.intro }
        pathname={pageData.fields.slug}
      />
      <HeroBanner title={pageData.frontmatter.title}
                  introduction={pageData.frontmatter.intro}
                  imageSources={heroImageSources}/>

      {intro}
      <div className={styles.projects_index__project_wrapper}>
      { projects.map(function(project, index) {
        return <Project key={ index }
                       name={ project.frontmatter.name }
                       excerpt={ project.frontmatter.intro }
                       image={ project.frontmatter.featuredImage }
                       url={ project.fields.slug}/>;
      }) }
      </div>
      <ProjectsCta />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
        intro
        largeHero {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        smallHero {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
      fields {
        slug
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/projects\\/[a-z0-9\\\\-]+\\/[a-z0-9\\\\-]+.md$/"}
      }
      sort: {
        fields: frontmatter___rank,
        order: DESC
      }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          name
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

